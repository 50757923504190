import React, { useState } from "react";
import logoDark from "../../assets/logo/logo-header.svg";
import { Link } from "react-router-dom";
// import { useTheme } from "../../store/ThemeContext";
// import logoLight from "../../assets/logo/logo-header-light.svg";

const Logo = () => {
  // const { theme } = useTheme();
    const [mounted, setMounted] = useState(false);

    React.useEffect(() => {
      setMounted(true); 
    }, []);

 return mounted ? (
   <Link to="/">
     <div className="brand-logo mb-3">
       <img
         src={logoDark}
         alt="Clever Compliance"
         title="Clever Compliance"
         className="img-fluid"
         style={{ padding: "0px 22px", margin: "14px 0px" }}
       />
     </div>
   </Link>
 ) : null;
};

export default Logo;